import { useEffect, useState } from "react"
import { Modal } from "components"
import { Mail, Gear } from "icons"
import { axios, getError, getUserModules, sendOTPToEmailConfig } from "lib"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { AuthApps } from "modules/Profile/sections/Settings/sections/AuthApps.section"
import { Email } from "modules/Profile/sections/Settings/sections/Email.section"
import { getAppSettingsByTenant } from "store"
import { getDataCounts } from "store/Actions/count"

const authMethods = [
    {
        name: "authApps",
        icon: (fill) => <Gear fill={fill} />,
        title: "Authenticator Apps",
        description:
            "Get codes from an app like Google Authenticator, Microsoft Authenticator etc.",
    },
    {
        name: "email",
        icon: (fill) => <Mail fill={fill} />,
        title: "Email",
        description:
            "We will send you an email if you need to user your backup login method.",
    },
]

export const ForceMFA = () => {
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState("authApps")

    // const [show, setShow] = useState(
    //   Boolean(localStorage.getItem('forceClientMFA'))
    // )

    const [show, setShow] = useState(
        localStorage.getItem("forceClientMFA") &&
            localStorage.getItem("forceClientMFA").trim() === "true"
    )

    // Sub Modals State
    const [authApps, setAuthApps] = useState(false)
    const [email, setEmail] = useState(false)

    const dispatch = useDispatch()
    const { user, hasMFA, token } = useSelector((state) => state?.auth)

    useEffect(() => {
        ;(async () => {
            await dispatch(getAppSettingsByTenant({ isAdmin: true }))
            const storedValue = localStorage.getItem("forceClientMFA")
            const booleanValue = storedValue && storedValue.trim() === "true"
            setShow(booleanValue)
        })()

        // const fetchData = async () => {
        //   if (user?.id && token) {
        //     try {
        //       await dispatch(getAppSettingsByTenant({ isAdmin: true }))

        //       console.log(show)
        //     } catch (error) {
        //       // Handle any errors
        //       console.error('Error fetching data:', error)
        //       // console.log('first')
        //     }
        //   }
        // }

        // fetchData()
    }, [])

    return (
        <>
            {/* Step 1 */}
            <Modal
                handleSubmit={async () => {
                    if (active === "authApps") {
                        setAuthApps(true)
                    } else if (active === "email") {
                        try {
                            setLoading(true)
                            const { url } = sendOTPToEmailConfig()
                            const res = await axios.post(url, {
                                userId: user.id,
                            })
                            toast.success(res?.data?.messages[0])
                            setLoading(false)
                            setEmail(true)
                        } catch (error) {
                            setLoading(false)
                            toast.error(getError(error))
                        }
                    }
                    setShow(false)
                }}
                show={show && !hasMFA}
                backdrop={"static"}
                setShow={setShow}
                heading="Enable MFA/OTP to continue"
                submitText="Continue"
                loading={loading}
                noCancel={true}
                customBody={
                    <div className="pb-[32px]">
                        <h6 className="mb-[32px] text-[#92928F] text-[14px]">
                            In addition to your username and password, you'll
                            have to enter a code (delivered via app or SMS) to
                            log into your account.
                        </h6>
                        {authMethods.map(
                            ({ name, icon, title, description }) => (
                                <div
                                    onClick={() => setActive(name)}
                                    key={name}
                                    className={`cursor-pointer p-[20px] border-dashed border-1 rounded-[8px] flex items-center gap-[20px] mb-[16px] ${
                                        active === name
                                            ? "border-[#3699FF] bg-[#212E48]"
                                            : "border-[#323248] bg-[#1E1E2D]"
                                    }`}
                                >
                                    <div
                                        className={`w-[85px] h-[79px] flex items-center justify-center rounded-[8px] ${
                                            active === name
                                                ? "bg-[#3699FF33]"
                                                : "bg-[#15152166]"
                                        }`}
                                    >
                                        {active === name
                                            ? icon("#3699FF")
                                            : icon("#494b74")}
                                    </div>
                                    <div>
                                        <h3 className="text-white text-[16px] leading-[25px]">
                                            {title}
                                        </h3>
                                        <p className="text-[#92928F] text-[14px] max-w-[333px] leading-[28px]">
                                            {description}
                                        </p>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                }
            />
            {/* Step 2 (A) */}
            <AuthApps show={authApps} setShow={setAuthApps} />
            {/* Step 2 (B) */}
            <Email show={email} setShow={setEmail} />
        </>
    )
}

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    brandId: null,
    brand: {
        logoUrl: null,
        name: "Loading...",
    },
    loading: false,
}
const brandSlice = createSlice({
    name: "brand",
    initialState,
    reducers: {
        setBrandId(state, { payload }) {
            state.brandId = payload
        },
        getBrandPending(state) {
            state.loading = true
        },
        getBrandSuccess(state, { payload }) {
            localStorage.setItem("brandId", payload.id)
            state.loading = false
            state.brandId = payload.id
            if (payload.logoUrl)
                state.brand.logoUrl = "data:image/png;base64," + payload.logoUrl
            if (
                !localStorage.getItem("logoUrl") ||
                localStorage.getItem("logoUrl") !== state.brand.logoUrl
            )
                localStorage.setItem(
                    "logoUrl",
                    "data:image/png;base64," + payload.logoUrl
                )
            if (
                !localStorage.getItem("displayTitle") ||
                localStorage.getItem("displayTitle") !==
                    state.brand.displayTitle
            )
                localStorage.setItem("displayTitle", payload.displayTitle)

            if (payload.name) state.brand.name = payload.name
            state.brand.companyName = payload?.companyName
            state.brand.address = payload?.address
        },
        getBrandFail(state) {
            localStorage.removeItem("brandId")
            state.isLoading = false
        },
    },
})

const { reducer, actions } = brandSlice

export const { setBrandId, getBrandPending, getBrandSuccess, getBrandFail } =
    actions

export default reducer
